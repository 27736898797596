import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link as InternalLink } from 'gatsby';
import { Link as ExternalLink } from '@mui/material';
import Button from '@mui/material/Button';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import IconButton from '@mui/material/IconButton';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import { iconFileTypesMap, mapIconBasedOnFileType, typeToPageMap } from '../../utils/constants';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const CustomerNeedsContainer = styled(Paper)(({ theme }) => ({
  '& a': {
    textDecoration: 'none'
  },
  ...theme.typography.body2,
  '& .boxContainer': {
    flexGrow: 1,
    backgroundColor: '#ffffff !important'
  },
  '& .h5': {
    color: '#383838 !important',
    fontWeight: 'bold !important'
  },

  '& .bodyStyle1': {
    marginLeft: '0',
    '& ol': {
      paddingInlineStart: '15px'
    },
    '& > p': {
      lineHeight: 1.33,
      color: '#383838'
    },
    '& p': {
      margin: '5px auto !important',
      color: '#383838'
    },
    '& p:empty::after': {
      content: '"."',
      visibility: 'hidden'
    }
  },
  '& .iconWithText': {
    fontWeight: 600,
    marginBottom: '10px'
  },
  '& .IconAlign': {
    verticalAlign: 'middle !important',
    marginRight: '3px',
    marginLeft: '4px'
  },
  '& .buttonPost': {
    marginTop: '10px !important'
  },
  '& .alignLeft': {
    textAlign: 'left',
    padding: '10px 0px'

  }
}));

export default function CustomerNeeds({ data }) {
  return (
    <CustomerNeedsContainer>
      <Container>
        <Box className='boxContainer' p={{ xs: '20px 0px', lg: '60px 120px' }}>
          <Grid item xs={12}>
            <Item>
              <Typography variant='h3' className='h5'>{data.title.toUpperCase()}</Typography>
              <Typography variant='headingdividerline' />
            </Item>
          </Grid>
          <Grid container spacing={2} flexDirection={{ xs: 'column', md: 'row' }}>
            <Grid item xs={12} lg={8}>
              <Item>
                <Typography variant='body1' className='bodyStyle1'>
                  {documentToReactComponents(JSON.parse(data.detailPoints?.raw))}
                </Typography>
              </Item>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Item>
                {data?.assetTitle && data?.assetList && data.assetList.length > 0 && (<Typography variant='body1' className='iconWithText'>{data?.assetTitle.toUpperCase()}</Typography>) }
                {data?.assetList?.map(asset => {
                  const iconData = mapIconBasedOnFileType(asset);
                  const pageTypeName = asset?.link?.refPage?.__typename;
                  return (
                    <>
                      <Typography key={asset?.id}>
                        {asset?.downloadLink?.file?.url && asset?.title && (
                          <IconButton href={asset?.downloadLink?.file?.url} sx={{ fontSize: 15, alignItems: 'flex-start' }} disableRipple color='inherit' target={asset?.openInANewTab ? '_blank' : ''} className='alignLeft' download>
                            {iconData && iconData.icon && iconFileTypesMap[iconData.icon] !== undefined && iconFileTypesMap[iconData.icon] !== '' ? <iconData.DynamicIcon className='IconAlign' /> : <DownloadForOfflineIcon className='IconAlign' />}
                            <u>
                              {' '}
                              {asset.title}
                            </u>
                          </IconButton>
                        )}
                      </Typography>

                      {asset?.link && asset?.link?.url && asset?.link?.title && (
                        <ExternalLink target={asset?.link?.openInANewTab ? '_blank' : ''} href={asset.link.url.url}>
                          <Button variant='contained' color='primary' className='buttonPost'>{asset?.link.title}</Button>
                        </ExternalLink>
                      )}
                      {asset?.link && asset?.link?.refPage && asset?.link?.refPage?.slug && asset?.link?.entryTitle && (
                        <InternalLink target={asset?.link?.openInANewTab ? '_blank' : ''} to={typeToPageMap[pageTypeName] + asset.link.refPage.slug}>
                          <Button variant='contained' color='primary' className='buttonPost'>{asset?.link.entryTitle}</Button>
                        </InternalLink>
                      )}
                    </>
                  );
                })}
              </Item>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </CustomerNeedsContainer>

  );
}
