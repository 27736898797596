import * as React from 'react';
import PropType from 'prop-types';
import { graphql } from 'gatsby';
import Layout from '../../components/layout/layout';
import ProgramHero from '../../components/program-hero';
import About from '../../components/about';
import CustomerJourney from '../../components/customerjourney';
import CustomerNeeds from '../../components/customerneeds';
import ParticipatingBrands from '../../components/participatingbrands';
import KeyReminders from '../../components/keyreminders';
import BenefitTiers from '../../components/benefit-tiers';

function ProgramDetail({ data, location }) {
  const programData = data.contentfulPageProgram;
  const programHeroData = {
    category: programData.category,
    // title: programData?.title,
    shortTitle: programData?.programShortTitle,
    website: programData?.website,
    socialLinks: programData?.socialLinks,
    shortDescription: programData?.programShortDescription.programShortDescription,
    embeddedVideo: programData?.programVideo?.embeddedUrl.embeddedUrl,
    programImage: programData?.programImage,
    updatedAt: programData?.updatedAt,
    buttons: programData?.buttons
  };
  const aboutData = {
    logo: programData.logo.gatsbyImageData,
    title: programData.programTitle,
    description: programData.programDescription
  };

  const Page = 'PROGRAM';

  return (
    <Layout
      legalFootNote={programData?.legalFootNote?.legalFootNote}
      location={location}
      pageName={programHeroData.shortTitle}
      isMarketingMaterials={!!programData?.marketingMaterial?.length}
    >
      <ProgramHero data={programHeroData} />
      {programData.customerNeeds
      && <CustomerNeeds data={programData.customerNeeds} />}
      {programData.keyReminders
      && <KeyReminders data={programData.keyReminders} />}
      {programData.benefitTiers
      && <BenefitTiers data={programData?.benefitTiers} />}
      {programData.customerJourney
       && <CustomerJourney data={programData?.customerJourney} />}
      {programData.participatingBrands
      && <ParticipatingBrands data={programData.participatingBrands} /> }
      <About data={aboutData} value={Page} tags={programData?.metadata?.tags || []} />
    </Layout>
  );
}

export const data = graphql`
  query ProgramDetail($slug: String!) {
    contentfulPageProgram(slug: {eq: $slug}) {
      metadata {
        tags {
          id
          name
          contentful_id
        }
      }
      category
      programShortTitle
      programShortDescription {
        programShortDescription
      }
      programTitle
      programDescription {
        raw
      }
      updatedAt(formatString: "MMMM DD, YYYY")
      programImage {
        file {
          url
          fileName
        }
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          width: 730
          quality: 100
          resizingBehavior: SCALE
          placeholder: BLURRED
          cornerRadius: 10
        )
      }
      programVideo {
        embeddedUrl {
          embeddedUrl
        }
      }
      logo {
        gatsbyImageData(
          aspectRatio: 1.78
          jpegProgressive: true
          formats: WEBP
          resizingBehavior: SCALE
          placeholder: BLURRED
        )
      }
      website {
        ... on ContentfulCompExternalLink {
          id
          openInANewTab
          title
          url {
            url
          }
        }
      }
      customerJourney {
        title
        image {
          gatsbyImageData(
            jpegProgressive: true
            formats: WEBP
            placeholder: BLURRED
          )
        }
      }
      customerNeeds {
        ...customerNeedsAndKeyReminders
      }
      keyReminders {
        ...customerNeedsAndKeyReminders
      }
      socialLinks {
        facebookUrl
        instagramUrl
        linkedinUrl
        twitterUrl
        youtubeUrl
      }
      participatingBrands {
        id
        brandLink
        brandName
        brandLogo {
          file {
            url
            details {
              image {
                height
                width
              }
            }
          }
          gatsbyImageData(
            aspectRatio: 1.78
            jpegProgressive: true
            formats: WEBP
            resizingBehavior: SCALE
            placeholder: BLURRED
          )
        }
      }
      benefitTiers {
        ...benefitTiers
      }
      legalFootNote {
        legalFootNote
      }
      buttons {
        title
        link {
          ... on ContentfulInternalLink {
            openInANewTab
            entryTitle
            refPage {
              __typename
              ... on ContentfulPageUtility {
                id
                entryTitle
                contentful_id
                type
                slug
              }
            }
          }
          ... on ContentfulCompExternalLink {
            id
            openInANewTab
            title
            url {
              url
            }
          }
        }
      }
      marketingMaterial {   # To find whether programDetails has marketingMaterials or not
        id
      }
    }
  }

  fragment benefitTiers on ContentfulCompBenefitTier{
    benefitAmount
    homeValue
  }

  fragment ranInternalExternalLink on ContentfulRanCompLink {
    title
    link {
      ... on ContentfulInternalLink {
        openInANewTab
        entryTitle
        refPage {
          __typename
          ... on ContentfulPageProgram {
            slug
          }
          ... on ContentfulPageUtility {
            slug
          }
          ... on ContentfulPageEvent {
            slug
          }
          ... on ContentfulPageKnowledge {
            slug
          }
        }
      }
      ... on ContentfulCompExternalLink {
        title
        url {
          url
        }
        openInANewTab
        logo {
          file {
            fileName
            url
            contentType
          }
        }
      }
    }
  }

  fragment assetLists on ContentfulCompAssetLink {
    id
    title
    downloadLink {
      file {
        url
        fileName
        contentType
      }
    }
    openInANewTab
  }

  fragment customerNeedsAndKeyReminders on ContentfulCompInfo {
    title
    detailPoints {
      raw
    }
    assetTitle
    assetList {
      ...assetLists
      ...ranInternalExternalLink
    }
  }
`;

ProgramDetail.propTypes = {
  location: PropType.shape.isRequired
};

export default ProgramDetail;
