import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import ImageDisplay from '../image-display';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const ParticipatingBrandsContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: '#FFFFFF !important',
  '& .boxContainer': {
    flexGrow: 1,
   
  },
  '& .h5': {
    color: '#383838 !important',
    fontWeight: 'bold !important'
  }
}));

export default function ParticipatingBrands({ data }) {
  return (
    <ParticipatingBrandsContainer>
      <Container>
      <Box className='boxContainer' p={{ xs: '20px', lg: '92px 0px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <Typography variant='h3' className='h5'>PARTICIPATING BRANDS</Typography>
              <Typography variant='headingdividerline' />
            </Item>
          </Grid>

          <Grid
            container
            direction='row'
            justifyContent='flex-start'
            alignItems='center'
          >
            {data?.map(participatingBrand => (
              <Grid xs={4} md={2}>
                <Item sx={{ textAlign: 'center' }} key={participatingBrand?.id}>
                  <Link href={participatingBrand?.brandLink} target='_blank'>
                    <ImageDisplay data={participatingBrand?.brandLogo} />
                  </Link>
                </Item>
              </Grid>
            ))}
          </Grid>

        </Grid>
      </Box>
      </Container>
    </ParticipatingBrandsContainer>
  );
}
