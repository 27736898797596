import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Container from '@mui/material/Container';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const BenefitTiersContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  '& .boxContainer': {
    flexGrow: 1
  },
  '& .h5': {
    color: '#383838 !important',
    fontWeight: 'bold !important'
  },
  '& .cardContainer': {
    minWidth: '180px !important',
    textAlign: 'center !important'
  },
  '& .font14': {
    fontSize: '14px !important'
  },
  '& .textStyle1': {
    fontSize: '18px !important',
    fontWeight: 'bold !important'
  },
  '& .textStyle2': {
    fontSize: '14px !important',
    marginTop: '20px !important'
  },
  '& .table': {
    '& .MuiTableCell-head': {
      fontWeight: 700
    }
  }
}));

export default function BenefitTiers({ data }) {
  return (
    <BenefitTiersContainer>
      <Container>
      <Box className='boxContainer' p={{ xs: '20px 0px', lg: '100px 0px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item> 
              <Typography variant='h3' className='h5'>BENEFIT TIERS</Typography>
              <Typography variant='headingdividerline' />
            </Item>
          </Grid>
          <Grid item xs={12} lg={4}>
            <Item>
              <TableContainer component={Paper}>
                <Table className='table' size='medium'>
                  <TableHead>
                    <TableRow>
                      <TableCell>{data[0].homeValue}</TableCell>
                      <TableCell>{data[0].benefitAmount}</TableCell>
                    </TableRow>  
                    </TableHead>          
                    <TableBody>
                    {data?.slice(1).map((benefitTier, index) => (
                        <TableRow
                          key={index}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          <TableCell>{benefitTier.homeValue}</TableCell>
                          <TableCell>{benefitTier.benefitAmount}</TableCell>
                        </TableRow>        
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Item>
          </Grid>
        </Grid>
      </Box>
      </Container>
    </BenefitTiersContainer>
  );
}
