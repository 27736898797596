import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookOutlinedIcon from '@mui/icons-material/FacebookOutlined';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import IconButton from '@mui/material/IconButton';
import YouTubeIcon from '@mui/icons-material/YouTube';
import EmbeddedVideo from '../embedded-video';
import ImageDisplay from '../image-display';
import { typeToPageMap } from '../../utils/constants';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: 'white',
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const ProgramHeroContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  '& .BoxBackground': {
    flexGrow: 1,
    background: '#020249',
    backgroundImage: `image-set(
      url("/images/hero-bg.png") 1x,
      url("/images/hero-bg@2x.png") 2x
    )`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    paddingBottom: '26px'
    // background: 'transparent linear-gradient(180deg, #005284 0%, #40A59D 100%) 0% 0% no-repeat padding-box;'
  },
  '& .headerText': {
    padding: '24px 0 5px 0'
  },
  '& .ParagraphText': {
    marginBottom: '30px!important'
  },
  '& .ParagraphText1': {
    marginBottom: '10px!important',
    [theme.breakpoints.down('sm')]: {
      marginBottom: '5px !important'
    }
  },
  '& .website': {
    textTransform: 'none!important',
    textDecoration: 'none'
  },
  '& iframe': {
    [theme.breakpoints.down('lg')]: {
      width: '276px',
      height: '100%'
    }

  }
}));

export default function ProgramHero({ data }) {
  return (
    <ProgramHeroContainer>
      <Box className='BoxBackground'>
        <Container>
          <Grid container spacing={{ xs: 0, md: 2 }} p={{ xs: '50px 0', md: '50px 0 70px' }} alignItems='center' className='MainContainer' flexDirection={{ xs: 'column-reverse', sm: 'row' }}>
            <Grid item xs={12} sm={5}>
              <Item>
                <Typography variant='herobody1'>{data.category}</Typography>
                <Typography variant='heroheading' className='headerText'>
                  {data.shortTitle}
                </Typography>
                <Typography variant='herobody1' className='ParagraphText'>{data.shortDescription}</Typography>
                {data?.updatedAt && (
                  <Typography variant='caption'>
                    Last Updated:
                    {' '}
                    {data?.updatedAt}
                  </Typography>
                )}
                {data?.website?.url?.url && data?.website?.title && (
                  <a href={data?.website.url.url} target={data?.website?.openInANewTab ? '_blank' : ''} rel='noreferrer' style={{ color: '#FFF' }}>
                    <Typography variant='heroh5' className='website' marginTop='24px'>
                      <b>{data?.website?.title}</b>
                    </Typography>
                  </a>
                )}
                <Grid sx={{ margin: '10px 0px 30px 0px' }}>
                  <Box paddingTop='10px'>
                    <Typography variant='div'>
                      {data?.socialLinks?.instagramUrl
                        && (
                          <IconButton href={data.socialLinks.instagramUrl} target='_blank' disableRipple color='inherit'>
                            <InstagramIcon />
                          </IconButton>
                        )}
                      {data?.socialLinks?.facebookUrl
                        && (
                          <IconButton href={data.socialLinks.facebookUrl} target='_blank' disableRipple color='inherit'>
                            <FacebookOutlinedIcon />
                          </IconButton>
                        )}
                      {data?.socialLinks?.twitterUrl
                        && (
                          <IconButton href={data.socialLinks.twitterUrl} target='_blank' disableRipple color='inherit'>
                            <TwitterIcon />
                          </IconButton>
                        )}
                      {data?.socialLinks?.linkedinUrl
                        && (
                          <IconButton href={data.socialLinks.linkedinUrl} target='_blank' disableRipple color='inherit'>
                            <LinkedInIcon />
                          </IconButton>
                        )}
                      {data?.socialLinks?.youtubeUrl
                        && (
                          <IconButton href={data.socialLinks.youtubeUrl} target='_blank' disableRipple color='inherit'>
                            <YouTubeIcon />
                          </IconButton>
                        )}
                    </Typography>
                  </Box>
                </Grid>
                <Grid container spacing={2} alignItems='flex-start' justifyContent='flex-start' flexDirection={{ xs: 'column', sm: 'row' }} spacing={2}>
                  {data?.buttons && data?.buttons.length
                    && data?.buttons.map(b => {
                      const pageTypeName = b?.link?.refPage?.__typename;
                      return (
                        <Typography>
                          {
                            b?.link?.url?.url && b?.link?.title && (
                              <Button href={b.link.url.url} sx={{ margin: '0px 15px 15px 15px' }} target={b?.link?.openInANewTab ? '_blank' : ''} className='downloadBtn'>
                                {b?.link?.title}
                              </Button>
                            )
                          }
                          {
                            b?.link?.refPage && b?.link?.refPage?.slug && b?.link?.entryTitle && (
                              <Button href={typeToPageMap[pageTypeName] + b.link.refPage.slug} sx={{ marginLeft: '15px' }} target={b?.link?.openInANewTab ? '_blank' : ''} className='downloadBtn'>
                                {b?.link?.entryTitle}
                              </Button>
                            )
                          }
                        </Typography>
                      );
                    })}
                </Grid>
              </Item>
            </Grid>
            <Grid item xs={12} sm={7}>
              {data.embeddedVideo && <Item><EmbeddedVideo embeddedVideo={data.embeddedVideo} /></Item>}
              {!data.embeddedVideo && data.programImage && (
                <Item sx={{ borderRadius: '10px', border: '4px solid #fff', padding: 0, backgroundColor: '#fff' }}>
                  <ImageDisplay data={data.programImage} />
                </Item>
              )}
            </Grid>
          </Grid>
        </Container>
      </Box>
    </ProgramHeroContainer>
  );
}
