import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ImageDisplay from '../image-display';

const Item = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'left',
  color: theme.palette.text.secondary,
  boxShadow: 'none',
  backgroundColor: 'transparent'
}));

const CustomerJourneyContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: '#F0F0F0 !important',
  '& .boxContainer': {
    flexGrow: 1,
    
  },
  '& .divider': {
    //margin: '18.5px auto !important'
  },
  '& .h5': {
    color: '#383838 !important',
    fontWeight: 'bold !important'
  },
  '& .customerJurney': {
    width: '100%',
    borderRadius: '15px'
  },
  '& .cutomerJourneyContainer': {
    padding: '0px'
  }
}));

export default function CustomerJourney({ data }) {
  return (
    <CustomerJourneyContainer>
      <Container>
      <Box className='boxContainer' p={{ xs: '20px 0px', lg: '85px 0px' }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <Typography variant='h3' className='h5'>{data.title.toUpperCase()}</Typography>
              <Typography variant='headingdividerline' className='divider' />
            </Item>
            <Item sx={{ textAlign: 'left' }}>
              {/* <img className='customerJurney' src={data.image.gatsbyImageData.images.fallback.src} alt='Customer Journey' /></Item> */}
              <ImageDisplay data={data.image} />
            </Item>
          </Grid>
        </Grid>
      </Box>
      </Container>
    </CustomerJourneyContainer>
  );
}
