import React from 'react';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';

const EmbeddedVideoContainer = styled(Paper)(({ theme }) => ({
  ...theme.typography.body2,
  backgroundColor: 'transparent',
  textAlign: 'center',
  '& iframe': {
    borderRadius: '10px',
    border: '4px solid #fff'
  }
}));

function EmbeddedVideo({ embeddedVideo }) {
  return (
    <EmbeddedVideoContainer elevation={0}>
      <div dangerouslySetInnerHTML={{ __html: embeddedVideo }} />
    </EmbeddedVideoContainer>
  );
}

EmbeddedVideo.propTypes = {
  embeddedVideo: PropTypes.string.isRequired
};

export default EmbeddedVideo;
